export const GEOLOCATION_API_KEY = "deae8a17978f4d9d83242be8a497f93b";
export const USER_COOKIE_KEY = "wtf-user";
export const ENCRYPTION_KEY = "asasfwfaw23623";

export const IOS_APP_URL = "https://apps.apple.com/us/app/ouplonger/id1533831250";

export const NAVBAR_LINKS = [
  {
    name: "about",
    path: "#about",
  },
  {
    name: "features",
    path: "#features",
  },
  {
    name: "faq",
    path: "#faq",
  },
];

export const SOCIAL_LINKS = [
  {
    icon: require("../assets/images/insta-ico.svg").default,
    path: "https://www.instagram.com/divessentialfr/",
  },
  {
    icon: require("../assets/images/facebook-ico.svg").default,
    path: "#",
  },
];

export const EXTERNAL_LINKS = [
  {
    name: "conditionsAndTerms",
    path: "#",
  },
  {
    name: "GDPR",
    path: "#",
  },
  {
    name: "cookiePolicy",
    path: "#",
  },
];

export const emptyRatingRow = () => {
  return {
    title: {
      fr: "",
      en: "",
    },
    value: 0,
  };
};
