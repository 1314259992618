import React, { useEffect, useState } from 'react'
import ContentLoading from "../components/ContentLoading";
import i18next from "i18next";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { getSingleDoc, removeFromCollection } from "../firebase";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";


export default function SingleCenter() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();


  const data = [
    {
      fbKey: 'city',
      i18n: t('city')
    },
    {
      fbKey: 'address',
      i18n: t('address')
    },
    {
      fbKey: 'zipCode',
      i18n: t('zipCode')
    },
    {
      fbKey: 'verified',
      i18n: t('verified')
    },
    {
      fbKey: 'description',
      i18n: t('description')
    },
    {
      fbKey: 'ratings',
      i18n: t('ratings')
    },
    {
      fbKey: 'votes',
      i18n: t('votes')
    },
    {
      fbKey: 'available',
      i18n: t('available')
    },
    {
      fbKey: 'difficulty',
      i18n: t('difficulty')
    },
    {
      fbKey: 'coordinates',
      i18n: t('coordinates')
    },
    {
      fbKey: 'video',
      i18n: t('video')
    },

  ]


  const [currentLanguage, setCurrentLanguage] = useState('fr');

  const [isLoading, setIsLoading] = useState(true);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (!id) return;

    (async () => {
      setIsLoading(true);
      const querySnapshot = await getSingleDoc('centers', id);
      setCenter(querySnapshot);
      console.log(querySnapshot);
      setIsLoading(false);
    })();


  }, [id])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setCurrentLanguage(i18next.language.split('-')[0]);
    })

    setCurrentLanguage(i18next.language.split('-')[0]);
  }, [])

  const deleteCenter = async () => {
    const confirmed = window.confirm(t('deleteCenterConfirmation'));
    if (!confirmed) {
      return;
    }

    await removeFromCollection('centers', id);
    navigate('/dashboard/centers');
  }



  if (isLoading) return <ContentLoading overlay />;

  return (
    <div>
      <img src={center?.image} style={{ height: '400px' }} className="img-cover w-100" alt={center?.name[currentLanguage]} />


      <h1 className="my-3">
        {center.name[currentLanguage]} -
        {center?.city}
        {center.verified && <span className="mx-2 text-success bg-light rounded-circle">✔</span>}
        {center.new && <span className="ms-2 badge bg-info ">{t('new')}</span>}
      </h1>

      <div className="text-end mb-3">
        <NavLink
          to={`edit/`}
          className="btn btn-info me-3" >{t('updateCenter')}</NavLink>
        <button onClick={deleteCenter} className="btn btn-danger">{t('removeCenter')}</button>
      </div>

      {
        data.map((singleData, dataIndex) => {
          if (['city', 'address', 'zipCode', 'votes', 'difficulty'].includes(singleData.fbKey)) {
            return (
              <div key={`${singleData.fbKey}-${dataIndex}`} className='mb-4'>
                <h5>{singleData.i18n}</h5>
                <p className=" small mb-4">{center[singleData.fbKey]}</p>
              </div>)
          }

          if (['available'].includes(singleData.fbKey)) {
            return (
              <div className='mb-4'>
                <h5>{singleData.i18n}</h5>
                <p className=" small mb-4 fs-3"><i className={`${center[singleData.fbKey] ? 'bi bi-check-circle-fill text-success' : 'bi bi-x-circle-fill text-danger'}`}></i></p>
              </div>)
          }

          if (singleData.fbKey === 'description') {
            return <>
              <div className='mb-4'>
                <h5>{singleData.i18n}</h5>
                <p className=" small mb-4">{center[singleData.fbKey][currentLanguage]}</p>
              </div>
            </>
          }


          if (singleData.fbKey === 'video') {
            return <>
              <div className='mb-4'>
                <h5>{singleData.i18n}</h5>
                <video controls width={400} height={400 * (9 / 16)} >
                  <source src={center.video} type="video/mp4" />
                  Your browser does not support the video tag.

                </video>
              </div>
            </>
          }


          if (singleData.fbKey === 'coordinates') {
            return <>
              <div className='mb-4'>
                <h5>{singleData.i18n}</h5>
                <p>({center.lat} ,{center.lng})</p>
                <iframe title="map" src={`https://maps.google.com/maps?q=${center.lat}, ${center.lng}&z=15&output=embed`} width="360" height="270" frameBorder="0" style={{ width: '100%', border: 0 }} />
              </div>
            </>
          }


          if (singleData.fbKey === 'ratings') {
            return <>
              <div className='mb-4'>
                <h5>{singleData.i18n}</h5>
                {
                  center[singleData.fbKey].map((singleRate, index) => {
                    return (
                      <div className="d-flex align-items-center gap-4 mb-1" key={`${singleRate}-${index}`}>
                        {singleRate['title'][currentLanguage]}
                        <ReactStars
                          edit={false}
                          count={5}
                          value={singleRate.value}
                          size={36}
                          activeColor="#ffd700"
                        />
                      </div>
                    )
                  })
                }
              </div>
            </>
          }


          return null;
        })
      }

    </div>
  )
}
