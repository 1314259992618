import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { updateSingleDoc } from '../firebase';
import StarsRate from './StarsRate';

function SingleComment({ data, deleteHandler }) {
    const { t } = useTranslation();

    const { comment, center } = data;
    const [currentLanguage, setCurrentLanguage] = useState('fr');

    const date = new Date(comment.date.seconds * 1000)

    useEffect(() => {
        i18next.on('languageChanged', () => {
            setCurrentLanguage(i18next.language.split('-')[0]);
        })

        setCurrentLanguage(i18next.language.split('-')[0]);
    }, [])


    const handleDeleteClick = (e) => {
        const confirmed = window.confirm(t('deleteCommentConfirmation'));
        if (!confirmed) {
            return;
        }

        deleteHandler(center, comment.id)
    }

    return (
        <div className={`card single-comment mb-3 shadow-sm  ${comment.deleted && 'bg-secondary bg-opacity-25 opacity-50'}`} >
            <div className="card-body">
                {/* {comment.deleted && <span className='badge bg-danger'><i className="bi bi-trash"></i></span>} */}
                <div className='d-flex justify-content-between align-items-center'>
                    <small className='text-muted'>{date.toLocaleDateString()} , {date.toLocaleTimeString()}</small>
                    <StarsRate rate={comment.rating} />
                </div>

                <p>{comment.comment}</p>

            </div>

            <div className="card-footer bg-transparent">
                <div className='d-flex justify-content-between align-items-center'>
                    <small className='text-muted'>{comment.from.name} - {comment.from.email}</small>

                    <div>
                        {!comment.deleted && <button className='btn btn-danger btn-sm text-light' onClick={handleDeleteClick}><i className="bi bi-trash"></i></button>}
                    </div>
                </div>
            </div>


            <div className="card-footer bg-transparent">
                <small className='text-muted'>
                    <Link style={{ textDecoration: 'none' }} to={`/dashboard/centers/${center.id}`}>{center?.name[currentLanguage]}</Link>
                </small>
            </div>

        </div >
    )
}

export default SingleComment