import React from 'react'
import {useNavigate} from "react-router-dom";

export default function Error404() {
  const navigate = useNavigate();


  return (
    <div
      className="light-grey-bg d-flex flex-column align-items-center justify-content-center"
      style={{
        fontSize: '5rem',
        fontWeight: 'bolder',
        height: '100vh',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <span>Error 404</span>

      <button
        className={`btn btn-dark text-light px-5  btn-lg mt-4 `}
        onClick={() => {
          navigate("/");
        }}
      >
        <i className="bi bi-house-door me-2"/>
        Back to Home
      </button >
    </div>
  )
}
