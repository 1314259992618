import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";

const Links = [
  {
    icon: <i className="bi bi-speedometer2 me-2" />,
    name: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: <i className="bi bi-newspaper me-2" />,
    name: "Centers",
    to: "/dashboard/centers",
  },
  {
    icon: <i className="bi bi-chat-square-text me-2" />,
    name: "Comments",
    to: "/dashboard/comments",
  },
  {
    icon: <i className="bi bi-pin-map me-2" />,
    name: "Map",
    to: "/dashboard/centers-map",
  },
];

const Sidebar = () => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className="sidebar d-flex flex-column">
      <div className="sidebar-header">
        <h3 className="mb-4">Content</h3>
      </div>
      <div className="sidebar-body flex-grow-1 d-flex flex-column justify-content-between">
        <ul className="list-unstyled ">
          {Links.map(({ icon, name, to }, index) => (
            <li key={index} className="mb-2">
              <NavLink
                end
                className={({ isActive }) =>
                  `btn btn-white w-100 text-start ${
                    isActive ? "btn-dark" : undefined
                  }`
                }
                to={to}
              >
                {icon}
                {name}
              </NavLink>
            </li>
          ))}
        </ul>

        <div className="d-flex flex-column gap-3">
          <button
            className={`btn btn-secondary text-light w-100`}
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="bi bi-house-door me-2" />
            Home
          </button>

          <button
            className={`btn btn-danger text-light w-100`}
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <i className="bi bi-box-arrow-left me-2" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
