import React, { useEffect, useState} from 'react';
import { Navigate } from "react-router-dom";


const AdminProtect = (props) => {
  const {isAdmin, children} = props;
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
      setIsLoading(false);
  } , []);

  if(isLoading) {
    return <></>
  }

  if (!isAdmin) {
    return <Navigate to="/portalaccess" replace />;
  }

  return ( children )
}

export default AdminProtect;