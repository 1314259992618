import React from "react";
import { IOS_APP_URL } from "../utils/constants";
import appleStoreImg from "../assets/images/apple-store.png";
import cta1 from "../assets/images/cta-1.png";
import cta2 from "../assets/images/cta-2.png";
import { useTranslation } from "react-i18next";

export default function CallToAction() {
  const { t } = useTranslation();
  return (
    <section id="cta" className="container ">
      <div
        className=" position-relative overflow-hidden"
        style={{
          borderRadius: "20px",
          background: "#A4C5FF",
        }}
      >
        <div className="row">
          <div className="col-md-7 d-flex flex-column justify-content-between py-5">
            <p className="ms-md-5 fs-1 fw-bold text-center text-md-start">
              {t("downloadoulonger")}
            </p>

            <a
              //   rel="noreferrer"
              //   target="_blank"
              href={IOS_APP_URL}
              className="mt-4 ms-md-5 mx-auto"
            >
              <img
                className="img-fluid"
                src={appleStoreImg}
                alt="apple-store"
              />
            </a>
          </div>
          <div className="col-md-5 ">
            {/* <img className="img-fluid" src={cta1} alt="cta" /> */}

            <div
              className="position-relative"
              style={{
                height: "300px",
              }}
            >
              <img
                className="img-fluid"
                src={cta2}
                alt="cta"
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "60%",
                  transform: "translateX(-50%)",
                  zIndex: 1,
                }}
              />

              <img
                className="img-fluid"
                src={cta1}
                alt="cta"
                style={{
                  position: "absolute",
                  left: "40%",
                  bottom: "0",
                  transform: "translateX(-50%)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
