import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="">
      <div className="container text-center">
        <p
          className="text-center underline-header section-header"
        >
          {t("aboutHeader")}
        </p>
        <p className="mt-3 mx-auto text-center text-container">
          {t("aboutSubHeader")}
        </p>
      </div>
    </section>
  );
}
