import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ContentLoading from '../components/ContentLoading';
import SingleComment from '../components/SingleComment';
import { getAllCollection, updateSingleDoc } from '../firebase';

function Comments() {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const unOrderedComments = [];
    const [comments, setComments] = useState([]);



    const getCenters = useCallback(async () => {
        const centers = await getAllCollection('centers');

        centers.forEach(center => {

            if (center?.comments?.length) {
                center.comments.forEach(comment => {

                    const commentToAdd = {
                        center,
                        comment
                    }
                    unOrderedComments.push(commentToAdd)
                    // setComments(prevState => [...prevState, commentToAdd])
                })
            }
        })

        // ordered comments
        const orderedComments = unOrderedComments.sort((a, b) => (a.comment.date.seconds > b.comment.date.seconds ? 1 : -1));

        setComments(orderedComments)
        setIsLoading(false);
    }, [])

    useEffect(() => {
        getCenters();
    }, [getCenters])


    const deleteHandler = (center, commentId) => {

        // prepare the final object
        const finalData = { ...center };
        for (let index = 0; index < center.comments.length; index++) {
            const element = center.comments[index];
            console.log({ element })
            if (element.id === commentId) {
                element.deleted = true;
                break
            }
        }

        console.log({ finalData })

        updateSingleDoc('centers', center.id, finalData)
            .then(() => {
                // mark the deleted comment from list
                const cTemp = [...comments]
                for (let index = 0; index < cTemp.length; index++) {
                    const element = cTemp[index];

                    if (element.id === commentId) {
                        element.deleted = true;
                        break
                    }
                }
                setComments(cTemp)
            }).catch(err => {
                console.log(err);
            });
    }


    return (
        <div>
            <h1>{t('comments')}</h1>

            {
                isLoading ? <ContentLoading overlay /> : <>
                    {
                        comments.map((comment, index) => <SingleComment key={`comment-${index}`} deleteHandler={deleteHandler} data={comment} />)
                    }
                </>
            }
        </div>
    )
}

export default Comments