import React, {useContext} from 'react'
import {UserContext} from "../contexts/UserContext";

export default function Dashboard() {
  const {user}  = useContext(UserContext);


  return (
    <div>
        <h1>{user?.email}</h1>
        
    </div>
  )
}
