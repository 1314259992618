import React from "react";
import { IOS_APP_URL, NAVBAR_LINKS } from "../utils/constants";
import logo from "../assets/images/logo.svg";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { t } = useTranslation();

  return (
    <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light ">
      <div className="container">
        <button
          className="navbar-toggler text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="/#">
          <img height="60px" src={logo} alt="logo" />
        </a>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            {NAVBAR_LINKS.map((link, index) => (
              <li
                className="nav-item mx-0 mx-sm-3 mx-md-4 mx-lg-5 "
                key={index}
              >
                <a className="nav-link text-light fs-5" href={link.path}>
                  {t(link.name)}
                </a>
              </li>
            ))}
          </ul>
          <a
            href={IOS_APP_URL}
            // rel="noreferrer"
            // target="_blank"
            className="download-btn btn btn-info border-0 py-3 px-5 text-light fs-5 "
            style={{
              background:
                "linear-gradient(80.91deg, #5D98FF -1.33%, #0052DE 103.15%)",
              boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.25)",
              borderRadius: "18px",
            }}
          >
            {t("downloadApp")}
          </a>
        </div>
      </div>
    </nav>
  );
}
