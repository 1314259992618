import React from "react";
import Sidebar from "../components/Sidebar";
import CreateQuizFab from "../components/CreateQuizFab";
import { Outlet } from "react-router-dom";

export default function SideBarLayout() {
  return (
    <div className="dashboard d-flex align-items-start">
      <Sidebar/>

      <main className=" flex-grow-1 w-100">
        <div className="content">
          <CreateQuizFab />

          <Outlet/>
        </div>
      </main>
    </div>
  );
}