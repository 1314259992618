import React from "react";
import { useTranslation } from "react-i18next";
import arrow from "../assets/images/arrow.svg";

const FAQs = [
  {
    rating: "faq1Rating",
    answer: "faq1Answer",
  },
  {
    rating: "faq2Rating",
    answer: "faq2Answer",
  },
  {
    rating: "faq4Rating",
    answer: "faq4Answer",
  },
  {
    rating: "faq5Question",
    answer: "faq5Answer",
  },
  {
    rating: "faq6Question",
    answer: "faq6Answer",
  },
  {
    rating: "faq3Rating",
    answer: "faq3Answer",
  },
];

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <section id="faq">
      <div className="container">
        <div className="mt-5 ">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {FAQs.map((faq, index) => (
              <div key={index} className="accordion-item bg-transparent">
                <h4 className="accordion-header" id={`flush-heading${index}`}>
                  <button
                    className="accordion-button collapsed bg-transparent"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                  >
                    <div className="">
                      <p className="fs-2 mb-0 fw-bold ">
                        <img src={arrow} alt="down" />
                        {t(faq.rating)}
                      </p>
                    </div>
                  </button>
                </h4>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-3 mx-auto ">{t(faq.answer)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
