import React from "react";
import {
  EXTERNAL_LINKS,
  IOS_APP_URL,
  NAVBAR_LINKS,
  SOCIAL_LINKS,
} from "../utils/constants";
import appleStoreImg from "../assets/images/apple-store.png";
import logo from "../assets/images/logo-black.svg";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div id="footer" className="pt-5 pb-3">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center ">
          <div>
            <ul className="list-unstyled list-inline">
              {NAVBAR_LINKS.map((link, index) => (
                <li className="list-inline-item me-5" key={index}>
                  <a className="nav-link text-dark fs-6" href={link.path}>
                    {t(link.name)}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <a
            //   rel="noreferrer"
            //   target="_blank"
            href={IOS_APP_URL}
            className=""
          >
            <img className="img-fluid" src={appleStoreImg} alt="apple-store" />
          </a>
        </div>

        <ul className="list-unstyled list-inline text-center text-md-start">
          {SOCIAL_LINKS.map((link, index) => (
            <li className="list-inline-item" key={index}>
              <a
                className="nav-link px-2"
                href={link.path}
                rel="noreferrer"
                target="_blank"
              >
                <img src={link.icon || ""} alt="link" />
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Terms and conditions */}
      <div className="container mt-4">
        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center gap-4">
          <img height="60px" alt="logo" src={logo} />

          <ul className="list-unstyled  list-inline text-center ms-0 ms-md-5 mb-md-0">
            {EXTERNAL_LINKS.map((link, index) => (
              <li
                className="list-inline-item ms-4 d-block d-md-inline-block"
                key={index}
              >
                <a className="nav-link text-dark small" href={link.path}>
                  {t(link.name)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="text-center small mt-3">{t("footerCopyrights")}</div>
    </div>
  );
}
