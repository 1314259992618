import React, {useContext, useEffect, useState} from "react";
import ContentLoading from "../components/ContentLoading";
import {getSingleDoc, logInWithEmailAndPassword} from "../firebase";
import {UserContext} from "../contexts/UserContext";
import {Navigate, useNavigate} from "react-router-dom";


export const AdminLogin = () => {
  const {login, user}  = useContext(UserContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);


  useEffect(() => {
    setInitialLoading(false);
  } , []);

  if(initialLoading) {
    return <></>
  }

  if (user.isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }


  const handleSubmit =  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    setError(""); // reset error


    logInWithEmailAndPassword(email, password)
      .then(async res=>{
        console.log(res)
        const userData : any = await getSingleDoc("users", res.user.uid);

        if(userData){
          if(userData.isAdmin){
            login(userData);
            navigate("/dashboard");
          }
          else{
            setError("You are not an admin");
          }
        }
      })
      .catch(err=>{
        setError("Something went wrong");
      })
      .finally(()=>{
        setLoading(false);
      })
  }



  return (
    <div
      className="container"
      style={{
        marginTop: "300px",
      }}
    >
      <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
        <h5 className="card-title text-center text-capitalize mb-4">Admin Login</h5>
        <div className="card">
          {loading && <ContentLoading />}
          <div
            className="card-body"
            style={{
              width: "400px",
            }}
          >

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email address</label>
                <input type="email" className="form-control" id="email"
                       value={email}
                       placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password"
                       placeholder="Password" value={password}
                        onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="">
                <button type="submit" className="btn btn-dark w-100">Login</button>
              </div>


              {error && <div className="alert alert-danger mt-3 mb-1 small py-2">
                <i className="bi bi-exclamation-diamond me-2"/>
                {error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
