import React from "react";
import { useTranslation } from "react-i18next";

const FEATURES = [
  {
    image: require("../assets/images/features-1.png"),
    title: "feature1Header",
    description: "feature1Description",
  },
  {
    image: require("../assets/images/features-2.png"),
    title: "feature2Header",
    description: "feature2Description",
  },
  {
    image: require("../assets/images/features-3.png"),
    title: "feature3Header",
    description: "feature3Description",
  },
];

export default function Features() {
  const { t } = useTranslation();

  return (
    <section id="features" className="">
      <div className="container">
        <p className="fs-1 fw-bold text-center">{t("featuresHeader")}</p>

        <div className="row">
          <div className="col-sm-10 mx-auto">
            {FEATURES.map((feature, index) => (
              <div
                className={`row text-center text-md-start my-4 my-ms-5 py-ms-5 ${
                  index % 2 && "flex-row-reverse"
                }`}
                key={index}
              >
                <div className="col-md-4">
                  <img
                    className="img-fluid d-block mx-auto"
                    src={feature.image}
                    alt="feature"
                  />
                </div>
                <div className="col-md-8 ">
                  <div className="text-container d-flex flex-column h-100 justify-content-center">
                    <h1 className="fw-bold">{t(feature.title)}</h1>
                    <p className="">{t(feature.description)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
