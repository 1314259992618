import React, {useEffect, useRef, useState} from 'react'
import { getAllCollection } from "../firebase";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvent} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import i18next from "i18next";
import L from "leaflet";
import StarsRate from "../components/StarsRate";
import {Link} from "react-router-dom";

const markerIcon = new L.Icon({
  iconUrl: require('../assets/images/map-marker.svg').default,
  iconSize: [30,30]
})
// import mapMarketSVG from "../assets/images/map-marker.svg";

const getCenters = async (setCenters, setIsLoading) => {
  const querySnapshot = await getAllCollection('centers');
  setCenters(querySnapshot);
  setIsLoading(false)
}

export default function Centers() {
  const mapRef = useRef();
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const [isLoading, setIsLoading] = useState(true);
  const [centers, setCenters] = useState([]);

  const bounds={
    top:-100,
    left: 100,
    right: -100,
    bottom: 100
  }

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setCurrentLanguage(i18next.language.split('-')[0]);
    })
    setCurrentLanguage(i18next.language.split('-')[0]);
  }, [])


  useEffect(() => {
    getCenters(setCenters, setIsLoading);
  }, [])


  return (
    <div>
      {
        !isLoading && (
          <MapContainer
              ref={mapRef}
              style={{height: '800px'}}
              zoom={12}
              scrollWheelZoom={true}
          >

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapController centers={centers}/>
            {centers.map(c=> <RenderMarker key={c.id} c={c} currentLanguage={currentLanguage}/>)}
          </MapContainer>
        )
      }
    </div>
  )
}


const MapController = ({centers}) => {
  const map = useMap()

  if(!centers.length) return;

  const bounds = L.latLngBounds() // Instantiate LatLngBounds object
  for (let c of centers) {

    const lat_lng = [c.lat, c.lng]
    const marker = L.marker(lat_lng,{
      icon: markerIcon
    }).addTo(map);

    // marker.bindPopup(`<div>${renderToStaticMarkup(<RenderMarker c={c} />)}></div>`)
    // marker.on('click', clickZoom)
    bounds.extend(lat_lng)      // Extend LatLngBounds with coordinates
  }
  map.fitBounds(bounds)

  function clickZoom(e) {
    map.setView(e.target.getLatLng(),10);
    return true;
  }

  return null
}



const RenderMarker = ({c, currentLanguage="en"}) => {
  const ratingAvg = c.ratings.reduce((total, next) => total + Number(next.value), 0) ;
  const name = c.name[currentLanguage];

  return (
      <Marker key={c.id} icon={markerIcon} position={[c.lat,c.lng]}>
        <Popup>
          <div className="d-flex gap-2 position-relative">
            <img className="rounded-3" width="70px" height="70px" src={c.image} alt={name} />
            <div>
              <h6 className="fw-bold">{name}</h6>
              <StarsRate rate={ratingAvg} />

            </div>
            <Link to={`/dashboard/centers/${c.id}/edit`} className="stretched-link"/>
          </div>
        </Popup>
      </Marker>
  )
}