import { NavLink } from "react-router-dom";


export default function CreateQuizFab() {


  return (
    <NavLink to="/dashboard/centers/create">
      <button
        style={{
          width: "60px",
          height: "60px",
          borderRadius: '50%',
          position: "fixed",
          bottom: "40px",
          right: "40px",
          zIndex: "999",
        }}
        className="btn btn-warning fw-bold shadow">Add</button>
    </NavLink>
  )
}