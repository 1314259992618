import React from 'react'

function StarsRate({ rate }) {
    const TOTAL = 5;

    return (
        <div>
            {[...Array(rate)].map((elementInArray, index) => (<i key={`fill-${index}`} className="bi bi-star-fill"></i>))}
            {[...Array(TOTAL - rate)].map((elementInArray, index) => (<i key={`empty-${index}`} className="bi bi-star"></i>))}

        </div>
    )
}

export default StarsRate