import React, { useEffect, useState } from 'react'
import { getAllCollection } from "../firebase";
import ContentLoading from "../components/ContentLoading";
import i18next from "i18next";
import CenterCard from "../components/CenterCard";

export default function Centers() {
  const [currentLanguage, setCurrentLanguage] = useState('fr');

  const [isLoading, setIsLoading] = useState(true);
  const [centers, setCenters] = useState([]);

  const getCenters = async () => {
    const querySnapshot = await getAllCollection('centers');
    setCenters(querySnapshot);
    setIsLoading(false);
  }

  useEffect(() => {
    getCenters();
  }, [])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setCurrentLanguage(i18next.language.split('-')[0]);
    })

    setCurrentLanguage(i18next.language.split('-')[0]);
  }, [])


  return (
    <div>
      <h1>Centers</h1>

      {
        isLoading ? <ContentLoading overlay /> :
          <div className="row">
            {centers.map(center => (
              <CenterCard key={center.id} center={center} currentLanguage={currentLanguage} />
            ))}
          </div>
      }

    </div>
  )
}
