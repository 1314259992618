import {createContext, useEffect, useState} from "react";
import Cookies from 'js-cookie'
import {USER_COOKIE_KEY} from "../utils/constants";
import {decryptWithAES, encryptWithAES} from "../utils/helpers";

export const UserContext = createContext();


export const UserProvider = ({ children }) => {
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = Cookies.get(USER_COOKIE_KEY);
    if (user) {
      setUser((JSON.parse(decryptWithAES(user))));
    }
  } , []);

  // Login updates the user data with a name parameter
  const login = (user) => {
    setUser(user);
    // set user to cookies
    Cookies.set(USER_COOKIE_KEY,encryptWithAES(JSON.stringify(user)), {
      secure: true ,
      expires: 1
    });
  };

  // Logout updates the user data to default
  const logout = () => {
    setUser({});
    // remove user from cookies
    Cookies.remove(USER_COOKIE_KEY);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}
