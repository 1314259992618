import React from "react";
import { NavLink } from "react-router-dom";

const CenterCard = ({ center, currentLanguage }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-12 mb-4" key={center.id}>
      <div className="card h-100">
        <img src={center.image} className="card-img-top bg-primary bg-opacity-50 img-cover" style={{ height: '250px' }} alt={center.id} />
        <div className="card-body">
          <h5 className="card-title">{center?.name[currentLanguage] || center?.name}</h5>
          <NavLink to={`${center.id}`} className=" stretched-link" />
        </div>
      </div>
    </div>
  );
}


export default CenterCard;