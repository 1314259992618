import React, { useEffect, useState } from 'react'
import { availableLanguages } from "../i18nextConf";
import { getSingleDoc, updateSingleDoc, uploadFile } from "../firebase";
import { useTranslation } from "react-i18next";
import ContentLoading from "../components/ContentLoading";
import { useNavigate, useParams } from "react-router-dom";
import { emptyRatingRow, GEOLOCATION_API_KEY } from "../utils/constants";
import { v4 } from 'uuid';
import ReactStars from "react-rating-stars-component";



export default function CreateCenters() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();



  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [ratings, setRatings] = useState([]);


  const [formData, setFormData] = useState({
    image: '',
    video: '',
    name: {},
    description: {},
    address: '',
    autoFinder: '',
    city: '',
    zipCode: '',
    lat: '',
    lng: '',
    votes: 0,
    difficulty: 1,
    new: false,
    verified: false,
    available: true,
    ratings: []
  });


  // Get data
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getSingleDoc('centers', id);
      setFormData({
        image: data.image,
        video: data.video,
        name: { ...data.name },
        description: { ...data.description },
        address: data.address,
        autoFinder: '',
        city: data.city,
        zipCode: data.zipCode,
        lat: data.lat,
        lng: data.lng,
        votes: data.votes,
        new: data.new,
        verified: data.verified,
        ratings: data.ratings,
        difficulty: data.difficulty,
        available: data.available
      });
      setRatings(data.ratings);
      setIsLoading(false);
    })();
  }, [id]);



  const handleChangeMultiLanguage = (event) => {
    const { value, dataset } = event.target;
    const { field, lang, fieldIndex } = dataset;

    if (field === 'rating') {
      let tempRatings = [...ratings];
      tempRatings[+fieldIndex]['title'][lang] = value
      setRatings(prev => tempRatings)
    } else {
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [lang]: value
        }
      });
    }
  }

  const handleSetRating = (value, index) => {
    console.log(value)
    let tempRatings = [...ratings];
    tempRatings[+index].value = value
    setRatings(prev => tempRatings)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const finalData = {
      ...formData,
      // add ratings
      ratings: ratings,
      // add an id to the center
      id: v4(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };



    // upload image url
    if (imageFile) {
      finalData.image = await uploadFile('center', imageFile);
    }

    // upload video url
    if (videoFile) {
      finalData.video = await uploadFile('center', videoFile);
    }


    console.log({ finalData })



    updateSingleDoc('centers', id, finalData)
      .then(() => {
        console.log('center updated');
        navigate(`/dashboard/centers/${id}`);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });


  }

  const handleChange = (event) => {
    const { value, dataset } = event.target;
    let localValue = value;

    if (event.target.type === "checkbox") {
      localValue = event.target.checked
    }
    if (["number", "range"].includes(event.target.type)) {
      localValue = Number(localValue)
    }
    setFormData({
      ...formData,
      [dataset.field]: localValue
    });
  }

  const handleChangeFile = (event) => {
    const { dataset } = event.target;
    if (dataset.field === 'image') {
      setImageFile(event.target.files[0])
    }
    if (dataset.field === 'video') {
      setVideoFile(event.target.files[0])
    }

    // setFormData({
    //   ...formData,
    //   [dataset.field]: event.target.value
    // });
  }

  const handleRemoveRating = (index) => {
    const confirmed = window.confirm(t('deleteRatingConfirmation'));
    if (!confirmed) {
      return;
    }

    const tempRatings = [...ratings];
    tempRatings.splice(index, 1);
    setRatings(tempRatings);
  }


  const queryAddressToLatLng = async () => {

    let API_URL = `https://api.geoapify.com/v1/geocode/search?apiKey=${GEOLOCATION_API_KEY}`;
    if (formData.address) {
      API_URL += `&text=${formData.address}`
    }

    fetch(API_URL)
      .then(res => res.json())
      .then(response => {
        const locations = response?.features
        if (!(locations.length)) return;

        const lat = locations[0].properties.lat
        const lng = locations[0].properties.lon

        setFormData(prev => ({
          ...prev,
          lat,
          lng,
          // zipCode: data.postal_code,
          // city: data.county
        }))
      })
  }

  return (
    <div>
      <h1>{t('createCenter')}</h1>
      {isLoading ? <ContentLoading overlay /> : null}
      <form onSubmit={handleSubmit}>


        <div className="row">
          {
            Object.keys(formData).map((key, index) => {


              if (['image', 'video'].includes(key)) {
                return (
                  <div className="col-md-6 mb-4" key={`${key}`}>
                    <div className="form-group">
                      {
                        key === 'image' ?
                          <img src={formData[key]} className="img-thumbnail rounded-3 img-fluid d-block my-3" width="100" height="100" alt="" />
                          :
                          <video className="img-thumbnail rounded-3 img-fluid d-block my-3" controls width={100 / (9 / 16)} height={100} >
                            <source src={formData[key]} type="video/mp4" />
                          </video>
                      }
                      <label htmlFor={`${index}-${key}`}>{t(key)}</label>
                      <input onChange={handleChangeFile} data-field={key} type="file" name={`${index}-${key}`} className="form-control" id={`${index}-${key}`} placeholder={`${t(key)}`} />
                    </div>
                  </div>
                )
              }


              if (['name'].includes(key)) {
                return availableLanguages.map(language => (
                  <div className="col-md-6 mb-4" key={`${key}-${index}-${language.shortCode}`}>
                    <div className="form-group">
                      <label htmlFor={`${key}-${language.shortCode}`}>{t(key)} {language.shortCode}</label>
                      <input required value={formData[key][language.shortCode]} onInput={handleChangeMultiLanguage} data-field={key} data-lang={language.shortCode} type="text" name={`${language.shortCode}`} className="form-control" id={`${key}-${language.shortCode}`} placeholder={`${key} in ${language.shortCode}`} />
                    </div>
                  </div>
                ))
              }

              if (['description'].includes(key)) {
                return availableLanguages.map(language => (
                  <div className="col-md-6 mb-4" key={`${key}-${index}-${language.shortCode}`}>
                    <div className="form-group">
                      <label htmlFor={`${key}-${language.shortCode}`}>{t(key)} {language.shortCode}</label>
                      <textarea required value={formData[key][language.shortCode]} onInput={handleChangeMultiLanguage} data-field={key} data-lang={language.shortCode} type="text" name={`${language.shortCode}`} className="form-control" id={`${key}-${language.shortCode}`} placeholder={`${key} in ${language.shortCode}`} />
                    </div>
                  </div>
                ))
              }




              if (['lat', 'lng', 'city'].includes(key)) {
                return (
                  <div className="col-md-6 mb-4" key={`${key}-${index}`}>
                    <div className="form-group">
                      <label htmlFor={`${index}-${key}`}>{t(key)}</label>
                      <input required value={formData[key]} onInput={handleChange} data-field={key} type="text" name={`${index}-${key}`} className="form-control" id={`${index}-${key}`} placeholder={`${t(key)}`} />
                    </div>
                  </div>)
              }


              if (['zipCode', 'votes'].includes(key)) {
                return (
                  <div className={`col-md-${key === 'votes' ? '4' : '6'} mb-4`} key={`${key}`}>
                    <div className="form-group">
                      <label htmlFor={`${index}-${key}`}>{t(key)}</label>
                      <input required value={formData[key]} onInput={handleChange} data-field={key} type="number" min="0" name={`${index}-${key}`} className="form-control" id={`${index}-${key}`} placeholder={`${t(key)}`} />
                    </div>
                  </div>)
              }


              if (['new', 'verified', 'available'].includes(key)) {
                return (
                  <div className="col-md-4 pt-4 mt-2 mb-4" key={`${key}`}>
                    <div className="form-check form-switch">
                      <label className="form-check-label" htmlFor={`${index}-${key}`}>{t(key)}</label>
                      <input onChange={handleChange} className="form-check-input" checked={formData[key]} data-field={key} type="checkbox" name={`${index}-${key}`} id={`${index}-${key}`} />
                    </div>
                  </div>)
              }



              if (['difficulty'].includes(key)) {
                return (
                  <div className="col-md-6 mt-2 mb-4" key={`${key}`}>
                    <div className="">
                      <label className="" htmlFor={`${index}-${key}`}>{t(key)} ({formData[key]})</label>
                      <input onChange={handleChange} className="form-range" value={formData[key]} min="1" max="10" step="1" data-field={key} type="range" name={`${index}-${key}`} id={`${index}-${key}`} />
                    </div>
                  </div>)
              }




              if (['address'].includes(key)) {
                return (
                  <div className="col-md-6 mb-4" key={`${key}-${index}`}>
                    <div className="form-group">
                      <label htmlFor={`${key}`}>{t(key)}</label>
                      <input required value={formData[key]} onInput={handleChange} data-field={key} type="text" className="form-control" id={`${key}`} placeholder={`${key}`} />
                      <small id="emailHelp" className="form-text text-muted">{t('address-helper')}</small>
                    </div>
                  </div>
                )
              }

              if (['autoFinder'].includes(key)) {
                return (
                  <div className="col-md-6 pt-4 mb-4" key={`${key}-${index}`}>
                    <button
                      disabled={formData.address.length < 3}
                      type="button"
                      className="btn btn-info"
                      onClick={queryAddressToLatLng}
                    >{t('locate')}</button>
                  </div>
                )
              }
              return null;

            })
          }
          <iframe title="map" src={`https://maps.google.com/maps?q=${formData.lat}, ${formData.lng}&z=15&output=embed`} height="270" frameBorder="0" style={{ width: '100%', border: 0 }} />

          <h4 className='mt-5'>{t('rating')}</h4>
          {
            ratings.map((rating, index) => {

              return (
                <div className="col-md-12 mb-4" key={`rating-${index}-111`}>
                  <div className="card">
                    <div className="card-body  bg-primary bg-opacity-10">
                      <div className="text-end mb-4">
                        <button
                          type='button'
                          onClick={() => handleRemoveRating(index)}
                          className="btn btn-danger text-light"
                        >
                          {t('removeRating')}
                        </button>
                      </div>


                      <div className="row">
                        {
                          availableLanguages.map((language, langIndex) => (
                            <div className="col-md-4" key={`rating-${index}-${langIndex}`}>
                              <div className="form-group">
                                <label htmlFor={`${index}-${language.shortCode}`}>#{index + 1} {language.shortCode}</label>
                                <input required value={rating['title'][language.shortCode]} onInput={handleChangeMultiLanguage} data-field={'rating'} data-field-index={index} data-lang={language.shortCode} type="text" name={`${language.shortCode}`} className="form-control" id={`${index}-${language.shortCode}`} placeholder={`#${index + 1} in ${language.shortCode}`} />
                              </div>
                            </div>
                          ))
                        }
                        <div className="col-md-4 pt-2">
                          <ReactStars
                            count={5}
                            value={rating.value}
                            onChange={value => handleSetRating(value, index)}
                            size={36}
                            activeColor="#ffd700"
                          />
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <button
          onClick={() => setRatings(prevState => [...prevState, { ...emptyRatingRow() }])}
          type="button" className="btn btn-outline-success px-4 ">+ {t('addRating')}</button>



        <div className="text-end mt-5">
          <button type="submit" className="btn btn-primary px-4 "> {t('submit')}</button>
        </div>
      </form>
    </div >
  )
}
