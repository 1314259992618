import React from "react";
import { useTranslation } from "react-i18next";

import appleStoreImg from "../assets/images/apple-store.png";
import arrow from "../assets/images/arrow.svg";
import { IOS_APP_URL } from "../utils/constants";
import Navbar from "./Navbar";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundImage: `url(${require("../assets/images/header-bg.jpg")})`,
        height: "50vw",
        minHeight: "800px",
        maxHeight: "1000px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="d-flex flex-column position-relative"
    >
      {/* Navbar holder */}

      <div
        style={{
          height: "40px",
        }}
      />

      <Navbar />

      <div
        style={{
          height: "60px",
        }}
      />

      <div className="container w-75 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <h1
          className="text-center mt-auto text-light"
          style={{
            fontWeight: 600,
            fontSize: "56px",
            lineHeight: "145%",
          }}
        >
          {t("heroText")}
        </h1>

        <a
          //   rel="noreferrer"
          //   target="_blank"
          href={IOS_APP_URL}
          className="my-auto"
        >
          <img className="img-fluid" src={appleStoreImg} alt="apple-store" />
        </a>

        <a
          className="btn btn-light rounded-circle mt-auto mb-5 d-flex align-items-center justify-content-center"
          style={{
            // position: "absolute",
            // bottom: "30px",
            // left: "calc(50% - 25px)",
            width: "40px",
            height: "40px",
          }}
          href="#about"
        >
          <img src={arrow} alt="down" />
        </a>
      </div>
    </div>
  );
}
